
import {Component, Mixins} from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import {mapGetters} from "vuex";
import AppApiMixin from "@/components/mixins/AppApi.vue";

@Component({
  components: { Page },
  computed: {
    ...mapGetters({contractId: "contract/id"}),
    ...mapGetters({notificationsList: "notifications/notificationsList"}),
  },
})
class Notifications extends Mixins(AppApiMixin) {
  [x: string]: any;

  openNotificationPage(notificationId) {
    this.$router.push({name: 'notification', params: { contractId: this.contractId, notificationId: notificationId }});
  }
}

export default Notifications;
